import React, { useState } from "react";
import Nav from "../Navbar/Nav";
import Footer from "../Landing/Footer";
import Options from "./Options";
 

const OptionHandler = () => {
 

  return (
    <>
      <Nav  />
      <Options   />
      <Footer />
    </>
  );
};

export default OptionHandler;
