import React, { useState } from "react";
import Nav from "../Navbar/Nav";
import Footer from "../Landing/Footer";
import Brand from "./Brand";
 
 

const BrandHandler = () => {
 

  return (
    <>
      <Nav  />
      <Brand  />
      <Footer />
    </>
  );
};

export default BrandHandler;
